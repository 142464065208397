import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import InputField from "../common/Input";
import { PAGINATION } from "../../config/constant";
import Loader from "../common/loader";

const ViewPartner = ({ partners }) => {
  const [loading, setLoading] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const [offset, setOffset] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [pageSize, setPageSize] = useState(15);
  const [filteredPartners, setFilteredPartners] = useState([]);
  const [displayPartners, setDisplayPartners] = useState([]);

  const getStatusInfo = (statusId) => {
    if (Number(statusId) === 1) {
      return {
        class: "status-active",
        name: "Verified",
      };
    } else {
      return {
        class: "status-inactive",
        name: "Unverified",
      };
    }
  };

  useEffect(() => {
    const filtered =
      partners && partners.length > 0
        ? partners.filter(
            (partner) =>
              partner.name.toLowerCase().includes(searchKey.toLowerCase()) ||
              partner.dealer_id.toString().includes(searchKey)
          )
        : [];
    setFilteredPartners(filtered);
    const tempPageCount = Math.ceil(filtered.length / pageSize);
    setPageCount(tempPageCount);
    const startIndex = (pageNo - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    setDisplayPartners(filtered.slice(startIndex, endIndex));
  }, [partners, searchKey, pageNo, pageSize]);

  useEffect(() => {
    setOffset(0);
    setPageNo(1);
  }, [searchKey]);

  const handlePageClick = (page_no) => {
    setOffset(page_no);
    setPageNo(page_no + 1);
  };

  const handleSearchKeyInput = (search_key) => {
    setSearchKey(search_key);
  };

  return (
    <React.Fragment>
      {loading ? <Loader /> : null}
      <div className="reportess-tab-outer">
        <div className="reportess-tabs">
          <fieldset className="search-bx">
            <div className="material">
              <InputField
                id="search_by"
                type="text"
                className="form-input"
                placeholder="Search By Name or Dealer ID"
                name="search_by"
                autoComplete="off"
                value={searchKey}
                label="Search"
                onChange={(e) => handleSearchKeyInput(e.target.value)}
              />
              <i className="ic-search"></i>
            </div>
          </fieldset>
        </div>
        <div className="reportees-data-table data-table">
          <table>
            <thead>
              <tr>
                <th className="sorting">Name</th>
                <th className="sorting sorting_asc">Dealer ID</th>
                <th className="sorting" style={{ textAlign: "center", paddingRight: "100px" }}>
  Status
</th>


              </tr>
            </thead>
            <tbody>
              {!(displayPartners && displayPartners.length !== 0) && (
                <tr>
                  <td align="center" colSpan="2">
                    <h6 className="text-center text-danger text-bold">
                      No Records Found
                    </h6>
                  </td>
                </tr>
              )}
              {displayPartners &&
                displayPartners.length !== 0 &&
                displayPartners.map((partner, index) => {
                  const statusInfo = getStatusInfo(partner.status);
                  return (
                    <tr key={index}>
                      <td>{partner.name}</td>
                      <td>{partner.dealer_id}</td>
                      <td style={{ textAlign: "center", paddingRight: "80px" }}>
                        {" "}
                        <span className={statusInfo.class}>
                          {statusInfo.name}
                        </span>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          {filteredPartners.length > 0 && (
            <div className="pagination-bx" id="partnerList">
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                forcePage={offset}
                pageRangeDisplayed={5}
                onPageChange={(e) => handlePageClick(e.selected)}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default ViewPartner;
