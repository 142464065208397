import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// import Img_preview from "../../webroot/images/preview_img.png";
// import Selfie_SSS_ID from "../../webroot/images/SelfieSSS_ID.png";
// import SSS_ID from "../../webroot/images/SSS_ID.png";
// import Bank_Statement from "../../webroot/images/BankStatement.png";

// import NPWP from "../../webroot/images/NPWP.png";
// import Agreement_Form from "../../webroot/images/AgreementForm.png";
import { Zone, defaultZone } from '../../config/constant';
import MasterService from '../../services/MasterService';
import Loader from '../common/loader';



const PreviewConfirm = (props) => {
    let { ConfigList, user_detail } = props
    const [roleList, setRoleDetails] = useState([])
    const [userDetail, setUserDetail] = useState(user_detail)
    const [reportingRoleList, setReportingRoleList] = useState([])
    // const [ReferralName, setReferralName] = useState([])
    const [reportingManagerList, setReportingManagerList] = useState([])
    // const [bankList, setBankList] = useState([])
    const [createDisabled, setCreateDisabled] = useState(false)
    const [localityList, setLocalityList] = useState([])
    const [loading, setLoading] = useState(false)
    const [is_edit, setEdit] = useState(user_detail && user_detail.user_id && user_detail.user_id !== "" ? true : false)
    const historyLead = useNavigate();

    useEffect(() => {
        if (ConfigList.length === 0) {
            props.handleReset()
            if (is_edit) {
                historyLead("/edit-user/role_details?=" + userDetail.user_id);
            } else {
                historyLead("/add-user/role_details");
            }
        }
        getRoleList()
        getUsersByRole()
        getReportingRole()
        getBankList()
        getReferralUserList()
        getLocalityList()
        if (userDetail.user_id) {
            getUserLocationDetail(userDetail.user_id)
        }
    }, [])

    const getUserLocationDetail = async (user_id) => {
        if (userDetail.role_details.city && userDetail.role_details.city.length > 0) {

        } else {
            setLoading(true);
            await MasterService.post('/sfa/user/get-user_location', { user_id }).then(async function (response) {
                if (response.data.status === 200) {
                    let data = response.data.data
                    let tempUserDetail = { ...userDetail };

                    let state_data = data.location_response.map(({ state_id }) => state_id)
                    state_data = state_data.filter((item, i, ar) => ar.indexOf(item) === i);
                    let city_data = data.location_response.map(({ city_id }) => city_id)
                    city_data = city_data.filter((item, i, ar) => ar.indexOf(item) === i);

                    tempUserDetail.role_details.state = state_data
                    tempUserDetail.role_details.city = city_data
                    setUserDetail(tempUserDetail)
                    setLoading(false)
                }
            })
        }
    }

    const getLocalityList = async () => {
        if(userDetail.basic_details){
            await MasterService.getLocalityList(userDetail.basic_details.city).then((res) => {
                if(res && res.data && res.data.data && res.data.data.length){
                    let locality_list = res.data.data.map(elm => ({ id: elm.id, label: elm.value, value: elm.id }));
                    setLocalityList(locality_list)
                }
            })
        } 
    }

    const getReferralUserList = async () => {
        MasterService.post('/sfa/user/get_referral_user', {})
            .then(function (response) {
                if (response.data.status === 200) {
                    // var referral_list = response.data.data.map(elm => ({ id: elm.id, label: elm.name + " (" + elm.id + ")", value: elm.id }));
                    // setReferralName(referral_list);
                }
            })
            .catch(function (response) {
            });
    }

    const getBankList = async () => {
        MasterService.post('/sfa/user/bank-list', {})
            .then(function (response) {
                if (response.data.status === 200) {
                    // var bank_list = response.data.data.filter(elm => elm.bankId === basic_details.bank_id);
                    // setBankList(bank_list);
                }
            })
            .catch(function (response) {
            });
    }

    const getRoleList = async () => {
        MasterService.post('/sfa/user/role_list', {})
            .then(function (response) {
                if (response.data.status === 200) {
                    let role_list = response.data.data.filter(elm => elm.id === userDetail.role_details.role);
                    setRoleDetails(role_list)
                }
            })
            .catch(function (response) {
            });
    }

    const getUsersByRole = async () => {
        MasterService.post('/sfa/user/get-users', { role_id: userDetail.role_details.reporting_role })
            .then(function (response) {
                if (response.data.status === 200) {
                    let reporting_manager_list = response.data.data.user_list.filter(elm => elm.id === userDetail.role_details.reporting_manager);
                    setReportingManagerList(reporting_manager_list)
                }
            })
            .catch(function (response) {
            });
    }
    const getReportingRole = async () => {
        MasterService.post('/sfa/user/reporting_role_list', { role_id: userDetail.role_details.role })
            .then(function (response) {
                if (response.data.status === 200) {
                    let reporting_role_list = response.data.data.filter(elm => elm.id === userDetail.role_details.reporting_role);
                    setReportingRoleList(reporting_role_list)
                }
            })
            .catch(function (response) {
            });
    }

    const handleClickCancel = () => {
        if (user_detail?.user_id) {
            historyLead('/view-user/' + user_detail?.user_id,{ replace: true })
        }
    }

    const handleClick = async () => {
        setCreateDisabled(true)
        let result = isValidate() 
        if (result.formIsValid) { 
            await props.handleCreateUser()
        } else { 
            let pathname = ""
            if (result.basic_detail_error === "true") {
                if (is_edit) {
                    pathname = '/edit-user/basic_details'
                } else {
                    pathname = '/add-user/basic_details'
                }
            }
            if (result.role_error === "true") {
                if (is_edit) {
                    pathname = '/edit-user/role_details'
                } else {
                    pathname = '/add-user/role_details'
                }
            }
            if (result.document_error === "true") {
                if (is_edit) {
                    pathname = '/edit-user/documents'
                } else {
                    pathname = '/add-user/documents'
                }
            }

            if (is_edit) {
                historyLead(pathname + "?=" + userDetail.user_id, { tempErrors: result.tempErrors });
            } else {
                historyLead(pathname, { tempErrors: result.tempErrors });
            }
        }
    }

    const isValidate = () => {
        let tempUserDetail = { ...userDetail };
        let formIsValid = true;
        let tempErrors = {};
        let role_error = "false"
        let basic_detail_error = "false"
        let document_error = "false"
        // if (is_edit && tempUserDetail.basic_details["password"] === defaultPassword) {
        //     tempUserDetail.basic_details["password"] = ""
        //     tempUserDetail.basic_details["confirm_password"] = ""
        // }
        if (!tempUserDetail.role_details["state"]) {
            formIsValid = false;
            role_error = "true";
            tempErrors["state"] = 'State is required';
        }
        if (!tempUserDetail.role_details["city"]) {
            formIsValid = false;
            role_error = "true";
            tempErrors["city"] = 'City is required';
        }
        if (!tempUserDetail.role_details["role"]) {
            formIsValid = false;
            role_error = "true";
            tempErrors["role"] = 'Role is required';
        }
        if (tempUserDetail.role_details["reporting_role"]!==5 && !tempUserDetail.role_details["reporting_role"]) {
            formIsValid = false;
            tempErrors["reporting_role"] = 'Reporting Role is required';
        }
        // if (tempUserDetail.role_details["reporting_manager"]!==5 && !tempUserDetail.role_details["reporting_manager"]) {
        //     formIsValid = false;
        //     role_error = "true";
        //     tempErrors["reporting_manager"] = 'Reporting Manager is required';
        // }

        // if (!tempUserDetail.basic_details["username"]) {
        //     formIsValid = false;
        //     basic_detail_error = "true";
        //     tempErrors["username"] = 'Username is required';
        // }
        // if (!tempUserDetail.basic_details["mobile_no"]) {
        //     formIsValid = false;
        //     basic_detail_error = "true";
        //     tempErrors["mobile_no"] = 'Mobile is required';
        // } else if (tempUserDetail.basic_details["mobile_no"].length < 9) {
        //     formIsValid = false;
        //     tempErrors["mobile_no"] = 'Mobile length must be between 9 and 12';
        // }
        // else if (tempUserDetail.basic_details["mobile_no"].length > 12) {
        //     formIsValid = false;
        //     tempErrors["mobile_no"] = 'Mobile length must be between 9 and 12';
        // }
        // if (!tempUserDetail.basic_details["email_id"]) {
        //     formIsValid = false;
        //     basic_detail_error = "true";
        //     tempErrors["email_id"] = 'Email is required';
        // }
        // else if (tempUserDetail.basic_details["email_id"].match(EMAIL_VALIDATION) == null) {
        //     tempErrors["email_id"] = 'Email is not valid';
        // }
        /* if (tempUserDetail.basic_details["npwp"] && tempUserDetail.basic_details["npwp"].replace(/[^0-9]/g, "").length < 15) {
            tempErrors["npwp"] = 'NPWP is not valid';
        } */
        // if (!tempUserDetail.basic_details["adhar_no"]) {
        //     formIsValid = false;
        //     tempErrors["adhar_no"] = 'Aadhar No is required';
        // }  
        // if (!tempUserDetail.basic_details["pan_no"]) {
        //     formIsValid = false;
        //     tempErrors["pan_no"] = 'PAN No is required';
        // } 
        // if (!tempUserDetail.basic_details["joining_date"]) {
        //     formIsValid = false;
        //     basic_detail_error = "true";
        //     tempErrors["joining_date"] = 'Joining Date is required';
        // }
        // if (!tempUserDetail.basic_details["bank_id"]) {
        //     formIsValid = false;
        //     tempErrors["bank_id"] = 'Bank Name is required';
        // }
        // if (!tempUserDetail.basic_details["bank_branch_name"]) {
        //     formIsValid = false;
        //     basic_detail_error = "true";
        //     tempErrors["bank_branch_name"] = 'Branch is required';
        // }
        // if (!tempUserDetail.basic_details["bank_account_number"]) {
        //     formIsValid = false;
        //     basic_detail_error = "true";
        //     tempErrors["bank_account_number"] = 'Account Number is required';
        // } else {
        //     let account_length = bankList.length ? bankList[0].valid_length : 0
        //     if (tempUserDetail.basic_details["bank_account_number"].length < account_length && account_length > 0) {
        //         tempErrors["bank_account_number"] = 'Format of Account Number must '+account_length+' digits';
        //     }
        // }
        // if (!tempUserDetail.basic_details["beneficiary_name"]) {
        //     formIsValid = false;
        //     basic_detail_error = "true";
        //     tempErrors["beneficiary_name"] = 'Beneficiary Name is required';
        // }
        // if (!tempUserDetail.basic_details["ifsc_code"]) {
        //     formIsValid = false;
        //     basic_detail_error = "true";
        //     tempErrors["ifsc_code"] = 'IFSC Code is required';
        // }
        /* if (!tempUserDetail.basic_details["date_of_birth"]) {
            formIsValid = false;
            basic_detail_error = "true";
            tempErrors["date_of_birth"] = 'DOB is required';
        } */
        // if (!tempUserDetail.basic_details["state_name"]) {
        //     formIsValid = false;
        //     basic_detail_error = "true";
        //     tempErrors["state"] = 'State is required';
        // }
        // if (!tempUserDetail.basic_details["city"]) {
        //     formIsValid = false;
        //     basic_detail_error = "true";
        //     tempErrors["city"] = 'City is required';
        // }
        // if (!tempUserDetail.basic_details["postal_code"]) {
        //     formIsValid = false;
        //     basic_detail_error = "true";
        //     tempErrors["postal_code"] = 'Postal Code is required';
        // }
        // if (!tempUserDetail.basic_details["current_occupation"] && validationForFields.current_occupation.includes(tempUserDetail.role_details.role)) {
        //     formIsValid = false;
        //     basic_detail_error = "true";
        //     tempErrors["current_occupation"] = 'Current Occupation is required';
        // }
        // if (!tempUserDetail.basic_details["company_name"] && validationForFields.company_name.includes(tempUserDetail.role_details.role)) {
        //     formIsValid = false;
        //     basic_detail_error = "true";
        //     tempErrors["company_name"] = 'Company Name is required';
        // }
        // if (!tempUserDetail.basic_details["designation"] && validationForFields.designation.includes(tempUserDetail.role_details.role)) {
        //     formIsValid = false;
        //     basic_detail_error = "true";
        //     tempErrors["designation"] = 'Designation is required';
        // }
        // if (!tempUserDetail.basic_details["tagging_id"] && validationForFields.tagging.includes(tempUserDetail.role_details.role)) {
        //     formIsValid = false;
        //     tempErrors["tagging_id"] = 'Tagging is required';
        // }
        // if (!tempUserDetail.basic_details["source"] && validationForFields.source.includes(tempUserDetail.role_details.role)) {
        //     formIsValid = false;
        //     basic_detail_error = "true";
        //     tempErrors["source"] = 'Source is required';
        // }
        // if (!tempUserDetail.basic_details["referral_name"] && validationForFields.referral_name.includes(tempUserDetail.role_details.role)) {
        //     formIsValid = false;
        //     basic_detail_error = "true";
        //     tempErrors["referral_name"] = 'Referral Name is required';
        // }
        // if (!tempUserDetail.basic_details["business_line"]) {
        //     formIsValid = false;
        //     basic_detail_error = "true";
        //     tempErrors["business_line"] = 'Business Line is required';
        // }

        // if (!is_edit && !tempUserDetail.basic_details["password"]) {
        //     formIsValid = false;
        //     basic_detail_error = "true";
        //     tempErrors["password"] = 'Password is required';
        // }
        // if (!is_edit && !tempUserDetail.basic_details["confirm_password"]) {
        //     formIsValid = false;
        //     basic_detail_error = "true";
        //     tempErrors["confirm_password"] = 'Confirm Password is required';
        // }
        // if (!(tempUserDetail.basic_details["password"] === "" && tempUserDetail.basic_details["confirm_password"] === "") && tempUserDetail.basic_details["confirm_password"] !== tempUserDetail.basic_details["password"]) {
        //     formIsValid = false;
        //     basic_detail_error = "true";
        //     tempErrors["confirm_password"] = 'Password does not match ';
        // }

        // for (let index = 0; index < userDetail.documents.length; index++) {
        //     const element = userDetail.documents[index];
        //     let config_doc = ConfigList.document.filter(e => e.id === element.document_id)
        //     if (element.doc_url === "" && config_doc[0].doc_required) {
        //         formIsValid = false
        //         document_error = "true"
        //         let doc_title = config_doc[0].doc_title
        //         tempErrors[doc_title] = doc_title + " is required"
        //     }
        // }

        if (Object.keys(tempErrors).length > 0) {
            formIsValid = false
        }
        let response = {
            formIsValid: formIsValid,
            basic_detail_error: basic_detail_error,
            tempErrors: tempErrors,
            role_error: role_error,
            document_error: document_error
        }
        return response;
    }

    const handleEdit = (pathname) => {
        let url = ""
        if (is_edit) {
            url = "/edit-user/" + pathname + "?=" + userDetail.user_id
            historyLead(url);
        } else {
            url = "/add-user/" + pathname
            historyLead(url);
        }
    }

    let { role_details, basic_details } = userDetail

    return (
        <React.Fragment>
            <div className='preview-confirmation p-xxl add-user-detail-filed'>
                {loading ? <Loader /> : null}
                <div className='top-heading d-flex'>
                    <h2>Role Details</h2>
                    <Link onClick={() => handleEdit("role_details")} className='edit-opt'>Edit</Link>
                </div>

                <div className='d-flex'>
                    <div className='role-detail-preview'>
                        <h3>Basic Details</h3>
                        <ul>
                            <li>
                                <span>Username</span> 
                                <label>{role_details.username ? role_details.username : "-"}</label> 
                            </li> 
                            <li>
                                <span>Email ID</span> 
                                <label>{role_details.email_id ? role_details.email_id : "-"}</label> 
                            </li>  
                            <li>
                                <span>Mobile No</span> 
                                <label>{role_details.mobile_no ? role_details.mobile_no : "-"}</label> 
                            </li>  
                            <li>
                                <span>Employee ID</span> 
                                <label>{role_details.employee_id ? role_details.employee_id : "-"}</label> 
                            </li>   
                        </ul>
                    </div> 
                </div>
                <div><br/></div>
                <div className='d-flex'>
                    <div className='role-detail-preview'>
                        <h3>Business Vertical</h3>
                        <ul>  
                            <li>
                                <span>Business</span>
                                <label>Ambak</label>
                            </li>
                            <li>
                                <span>Vertical</span>
                                <label>Home Loan</label>
                            </li>
                        </ul>
                    </div>
                    <div className='role-detail-preview'>
                        <h3>Map Region</h3>
                        <ul>
                            {/* {role_details.zone_id && */}
                            <li>
                                <span>Zone</span>
                                <label>{Zone.filter(e => e.id === defaultZone)[0].label}</label>

                            </li>
                            {/* } */}
                            {role_details.state &&
                                <li>
                                    <span>State</span>
                                    <label>{role_details.state ? role_details.state.length : "No"} States</label>
                                </li>
                            }
                            {role_details.city &&
                                <li>
                                    <span>City</span>
                                    <label>{role_details.city ? role_details.city.length : "No"} Cities</label>
                                </li>
                            }
                            {role_details.map_circle &&
                                <li>
                                    <span>Map Circle</span>
                                    <label>{role_details.map_circle} </label>
                                </li>
                            }
                        </ul>
                    </div>
                </div>

                <div className='d-flex m-xl-t p-sm-t br-bottom'>
                    <div className='role-detail-preview'>
                        <h3>Role and Reporting</h3>
                        <ul>
                            {roleList && roleList.length > 0 &&
                                <li>
                                    <span>Role</span>
                                    <label>{roleList[0].name}</label>
                                </li>
                            }
                            {reportingRoleList.length > 0 &&
                                <li>
                                    <span>Reporting Role</span>
                                    <label>{reportingRoleList[0].name}</label>
                                </li>
                            }
                            {reportingManagerList.length > 0 &&
                                <li>
                                    <span>Reporting Manager</span>
                                    <label>{reportingManagerList[0].name}</label>
                                </li>
                            }
                        </ul>
                    </div>
                </div>


{/*                 <div className='top-heading d-flex'>
                    <h2>Basic Details</h2>
                    <a onClick={() => handleEdit("basic_details")} className='edit-opt'>Edit</a>
                </div>
                <div className='d-flex'>
                    <div className='role-detail-preview'>
                        <h3>Profile Photo</h3>
                        <div className='profile-photo'>
                            {basic_details.profile_image && basic_details.profile_image !== "" ?
                                <img src={basic_details.profile_image} className="" alt="no pic" /> :
                                <label>No Image</label>
                            }
                        </div>
                    </div>
                    <div className='role-detail-preview preview-basic-details'>
                        <h3>Personal Detail</h3>
                        <ul>
                            {basic_details.username &&
                                <li>
                                    <span>Username</span>
                                    <label>{basic_details.username}</label>
                                </li>
                            }
                            {basic_details.mobile_no &&
                                <li>
                                    <span>Mobile</span>
                                    <label>{basic_details.mobile_no}</label>
                                </li>
                            }
                            {basic_details.email_id &&
                                <li>
                                    <span>Email</span>
                                    <label>{basic_details.email_id}</label>
                                </li>
                            }
                            {basic_details.adhar_no &&
                                <li>
                                    <span>Aadhar No</span>
                                    <label>{basic_details.adhar_no}</label>
                                </li>
                            }
                            {basic_details.pan_no &&
                                <li>
                                    <span>PAN No</span>
                                    <label>{basic_details.pan_no}</label>
                                </li>
                            }
                            {basic_details.employment_type &&
                                <li>
                                    <span>Employment Type</span>
                                    <label>{EmploymentType.findIndex(e => e.value === basic_details.employment_type) > -1 ? EmploymentType.filter(e => e.value === basic_details.employment_type)[0].label : "NA"}</label>
                                </li>
                            }
                            {basic_details.employee_id &&
                                <li>
                                    <span>Employee ID</span>
                                    <label>{basic_details.employee_id}</label>
                                </li>
                            }
                            {basic_details.joining_date && isNaN(Date.parse(basic_details.joining_date)) === false &&
                                <li>
                                    <span>Joining Date</span>
                                    <label>{new Date(basic_details.joining_date).toISOString().split('T')[0]}</label>
                                </li>
                            }
                            {basic_details.anniversary_date && isNaN(Date.parse(basic_details.anniversary_date)) === false &&
                                <li>
                                    <span>Anniversary</span>
                                    <label>{new Date(basic_details.anniversary_date).toISOString().split('T')[0]}</label>
                                </li>
                            }
                        </ul>
                    </div>
                </div>
                <div className='d-flex  p-sm-t'>
                    <div className='role-detail-preview preview-basic-details'>
                        <h3>Bank Details</h3>
                        <ul>
                            {bankList.length > 0 &&
                                <li>
                                    <span>Bank Name</span>
                                    <label>{bankList[0].bankName}</label>
                                </li>
                            }
                            {basic_details.bank_branch_name &&
                                <li>
                                    <span>Branch</span>
                                    <label>{basic_details.bank_branch_name}</label>
                                </li>
                            }
                            {basic_details.bank_account_number &&
                                <li>
                                    <span>Account Number</span>
                                    <label>{basic_details.bank_account_number}</label>
                                </li>
                            }
                            {basic_details.beneficiary_name &&
                                <li>
                                    <span>Beneficiary Name</span>
                                    <label>{basic_details.beneficiary_name}</label>
                                </li>
                            }
                            {basic_details.ifsc_code &&
                                <li>
                                    <span>IFSC Code</span>
                                    <label>{basic_details.ifsc_code}</label>
                                </li>
                            } 
                        </ul>
                    </div>
                </div>

                <div className='d-flex  p-sm-t'>
                    <div className='role-detail-preview preview-basic-details'>
                        <h3>Other Details</h3>
                        <ul>
                            {basic_details.date_of_birth && isNaN(Date.parse(basic_details.date_of_birth)) === false &&
                                <li>
                                    <span>DOB</span>
                                    <label>{new Date(basic_details.date_of_birth).toISOString().split('T')[0]}</label>
                                </li>
                            }
                            {basic_details.state_name && stateList.length > 0 && stateList.findIndex(e => e.id === basic_details.state_name) > -1 &&
                                <li>
                                    <span>State</span>
                                    <label>{stateList.findIndex(e => e.id === basic_details.state_name) > -1 ? stateList.filter(e => e.id === basic_details.state_name)[0].label : ""}</label>
                                </li>
                            }
                            {basic_details.city && cityList.length > 0 && cityList.findIndex(e => e.id === basic_details.city) > -1 &&
                                <li>
                                    <span>City</span>
                                    <label>{cityList.findIndex(e => e.id === basic_details.city) > -1 ? cityList.filter(e => e.id === basic_details.city)[0].label : ""}</label>
                                </li>
                            }
                            {basic_details.user_location !== 0 && localityList.length > 0 &&
                                <li>
                                    <span>Locality</span>
                                    <label>{localityList.findIndex(e => e.id === basic_details.user_location) > -1 ? localityList.filter(e => e.id === basic_details.user_location)[0].label : ""}</label>
                                </li>
                            }
                            {basic_details.current_occupation && BasicDetailConfig.current_occupation.findIndex(e => e.value === basic_details.current_occupation) > -1 &&
                                <li>
                                    <span>Current Occupation</span>
                                    <label>{BasicDetailConfig.current_occupation.findIndex(e => e.value === basic_details.current_occupation) > -1 ? BasicDetailConfig.current_occupation.filter(e => e.value === basic_details.current_occupation)[0].label : ""}</label>
                                </li>
                            }
                            {basic_details.company_name &&
                                <li>
                                    <span>Company</span>
                                    <label>{basic_details.company_name}</label>
                                </li>
                            }
                            {basic_details.designation && BasicDetailConfig.designation.findIndex(e => e.value === basic_details.designation) > -1 &&
                                <li>
                                    <span>Designation</span>
                                    <label>{BasicDetailConfig.designation.findIndex(e => e.value === basic_details.designation) > -1 ? BasicDetailConfig.designation.filter(e => e.value === basic_details.designation)[0].label : ""}</label>
                                </li>
                            }
                            {basic_details.tagging_id && ConfigList && ConfigList.tagging_roles && ConfigList.tagging_roles.length > 0 && ConfigList.tagging_roles.findIndex(e => e.value === basic_details.tagging_id) > -1 &&
                                <li>
                                    <span>Tagging</span>
                                    <label>{ConfigList.tagging_roles.findIndex(e => e.value === basic_details.tagging_id) > -1 ? ConfigList.tagging_roles.filter(e => e.value === basic_details.tagging_id)[0].label : ""}</label>
                                </li>
                            }
                            {basic_details.business_line && ConfigList && ConfigList.business_line && ConfigList.business_line.length > 0 && ConfigList.business_line.findIndex(e => e.value === basic_details.business_line) > -1 &&
                                <li>
                                    <span>Business Line</span>
                                    <label>{ConfigList.business_line.findIndex(e => e.value === basic_details.business_line) > -1 ? ConfigList.business_line.filter(e => e.value === basic_details.business_line)[0].label : ""}</label>
                                </li>
                            }
                            {basic_details.source && BasicDetailConfig.source.findIndex(e => e.value === basic_details.source) > -1 &&
                                <li>
                                    <span>Source</span>
                                    <label>{BasicDetailConfig.source.findIndex(e => e.value === basic_details.source) > -1 ? BasicDetailConfig.source.filter(e => e.value === basic_details.source)[0].label : ""}</label>
                                </li>
                            }
                            {basic_details.postal_code &&
                                <li>
                                    <span>Postal Code</span>
                                    <label>{basic_details.postal_code}</label>
                                </li>
                            }
                            {basic_details.house_number &&
                                <li>
                                    <span>House/Plot</span>
                                    <label>{basic_details.house_number}</label>
                                </li>
                            }            
                            {basic_details.aro_so_assigned &&
                                <li>
                                    <span>ARO/SO assigned</span>
                                    <label>{basic_details.aro_so_assigned}</label>
                                </li>
                            }
                            {basic_details.street_address &&
                                <li>
                                    <span>Street Address</span>
                                    <label>{basic_details.street_address}</label>
                                </li>
                            }
                            {basic_details.incentive_percentage > 0 &&
                                <li>
                                    <span>Incentive%</span>
                                    <label>{basic_details.incentive_percentage}</label>
                                </li>
                            }
                            {basic_details.address &&
                                <li className='address-txt'>
                                    <span>Address</span>
                                    <label>{basic_details.address}</label>
                                </li>
                            } 
                        </ul>
                    </div>
                </div>

                {is_edit &&
                    <div className='d-flex  p-sm-t'>
                        <div className='role-detail-preview preview-basic-details'>
                            <h3>Additional Details</h3>
                            <ul>
                                {basic_details.created_date && isNaN(Date.parse(basic_details.created_date)) === false &&
                                    <li>
                                        <span>Created Date</span>
                                        <label>{new Date(basic_details.created_date).toISOString().split('T')[0]}</label>
                                    </li>
                                }
                                {basic_details.approval_date && isNaN(Date.parse(basic_details.approval_date)) === false &&
                                    <li>
                                        <span>Approval Date</span>
                                        <label>{new Date(basic_details.approval_date).toISOString().split('T')[0]}</label>
                                    </li>
                                }
                                {basic_details.onboarded_from &&
                                    <li>
                                        <span>Onboarded From</span>
                                        <label>{basic_details.onboarded_from}</label>
                                    </li>
                                }
                                {basic_details.onboarded_by &&
                                    <li>
                                        <span>Onboarded By</span>
                                        <label>{basic_details.onboarded_by}</label>
                                    </li>
                                }
                                {basic_details.resign_date_1 && isNaN(Date.parse(basic_details.resign_date_1)) === false &&
                                    <li>
                                        <span>Resign Date 1</span>
                                        <label>{new Date(basic_details.resign_date_1).toISOString().split('T')[0]}</label>
                                    </li>
                                }
                                {basic_details.resign_date_2 && isNaN(Date.parse(basic_details.resign_date_2)) === false &&
                                    <li>
                                        <span>Resign Date 2</span>
                                        <label>{new Date(basic_details.resign_date_2).toISOString().split('T')[0]}</label>
                                    </li>
                                }
                                {basic_details.rejoin_date_1 && isNaN(Date.parse(basic_details.rejoin_date_1)) === false &&
                                    <li>
                                        <span>Rejoin Date 1</span>
                                        <label>{new Date(basic_details.rejoin_date_1).toISOString().split('T')[0]}</label>
                                    </li>
                                }
                                {basic_details.rejoin_date_2 && isNaN(Date.parse(basic_details.rejoin_date_2)) === false &&
                                    <li>
                                        <span>Rejoin Date 2</span>
                                        <label>{new Date(basic_details.rejoin_date_2).toISOString().split('T')[0]}</label>
                                    </li>
                                } 
                                {basic_details.warning_date_1 && isNaN(Date.parse(basic_details.warning_date_1)) === false &&
                                    <li>
                                        <span>Warning Date 1</span>
                                        <label>{new Date(basic_details.warning_date_1).toISOString().split('T')[0]}</label>
                                    </li>
                                } 
                                {basic_details.warning_date_2 && isNaN(Date.parse(basic_details.warning_date_2)) === false &&
                                    <li>
                                        <span>Warning Date 2</span>
                                        <label>{new Date(basic_details.warning_date_2).toISOString().split('T')[0]}</label>
                                    </li>
                                } 

                                {basic_details.comments &&
                                    <li>
                                        <span>Comments</span>
                                        <label>{basic_details.comments}</label>
                                    </li>
                                }


                            </ul>
                        </div>
                    </div>
                }

                <div className='d-flex  p-sm-t br-bottom'>
                    <div className='role-detail-preview preview-basic-details'>
                        <h3>Set Password</h3>
                        <ul>
                            <li>
                                <span>Password</span>
                                <label>{basic_details.password ? basic_details.password.replace(/./g, '*') : ""}</label>
                            </li>
                        </ul>
                    </div>
                    {validationForFields.referral_name.includes(userDetail.role_details.role) && ReferralName.length > 0 && basic_details.referral_name !== 0 &&
                        <div className='role-detail-preview'>
                            <h3>Reference</h3>
                            <ul>
                                <li>
                                    <span>Reference Name & ID</span>
                                    <label>{ReferralName.findIndex(e => e.id === basic_details.referral_name) > -1 ? ReferralName.filter(e => e.id === basic_details.referral_name)[0].label : ""}</label>
                                </li>
                            </ul>
                        </div>
                    }
                </div>


                <div className='top-heading d-flex'>
                    <h2>Documents</h2>
                    <a onClick={() => handleEdit("documents")} className='edit-opt'>Edit</a>
                </div>

                <div className='d-flex'>
                    <div className='role-detail-preview doc-preview-details' style={{ display: "flex" }}>
                        {ConfigList && ConfigList.document && ConfigList.document?.length > 0 && documents && documents?.length > 0 && documents.map((data, index) => (
                            <div key={index} className='upload-document-outer'>
                                {data.doc_url !== "" &&
                                    <div className="img-bx-select-opt">
                                        <div className="image-bx">
                                            <img src={data.doc_url} className="" alt="no_image" />
                                        </div>
                                        <span className='document-name'>{ConfigList.document.filter(e => parseInt(e.id) === data.document_id)[0].doc_title}</span>
                                    </div>
                                }
                            </div>
                        ))}
                    </div>
                </div> */}


                <div className='br-top-flex'>
                    {is_edit ? <div className="t-right">
                        <button className='btn-line m-xs-l' onClick={handleClickCancel.bind(this)}>
                            Cancel
                        </button>
                    </div> : null}
                    <div className="t-right">
                        <button disabled={createDisabled} className='btn-primary' onClick={handleClick.bind(this)}>
                            {is_edit ? "Update User" : "Create User"}
                        </button>
                    </div>
                </div>



            </div>

        </React.Fragment>
    )
}
export default PreviewConfirm;